import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import ServiceAuth from '../services/service_auth'
import { AuthContext } from '../Context/authProvider'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Header() {
    const navigate = useNavigate()
    const {auth, authDispatch} = useContext(AuthContext)



    return (
        <div className="container-fluid bg-body" style={{padding: 0}}>
            <div className="container-fluid row border-bottom"style={{padding: 0, margin: 0}}>                
                <div className="navbar-brand col text-center m-3 mb-2 ps-sm-5" style={{padding: 0,display:"flex"}}>
                    <h3 style={{color:"rgb(33,88,112)"}}>ESPACE &nbsp;</h3>
                    <span><h3 style={{backgroundColor:"rgb(33,88,112)", color:"white"}}>&nbsp; PRO &nbsp;</h3></span>
                </div>
                <div className="col-7 header-picture-div" style={{'padding': 0, 'width': 'fit-content'}}>
                    <a href="https://www.socotex.net/contact-socotex" target="_blank" rel='noreferrer noopener'>
                        <picture>
                            <source srcSet={process.env.PUBLIC_URL + '/BadgeSocotex.png'} media="(max-width: 630px)" width="85px" height="85px"/>
                            <img src={process.env.PUBLIC_URL + '/SocotexBandeau2.jpg'} alt="Bandeau Socotex" width="350px" height="92px"/>
                        </picture>
                    </a>
                </div>  
            </div>     

            <Navbar collapseOnSelect key="md" expand="md" className=" mb-3" bg="secondary" data-bs-theme="dark" style={{'backgroundColor':'#c9c9c9'}}>
                <Container fluid id="navbarContainer">
                    <Navbar.Toggle aria-controls={"offcanvasNavbar-expand-md"} style={{'background':'', 'borderColor':'lightgrey', 'borderWidth':'2px' }}/>
                    <Navbar.Offcanvas id={"offcanvasNavbar-expand-md"} aria-labelledby={"offcanvasNavbarLabel-expand-md"} placement="start" style={{'width':'50%'}}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={"offcanvasNavbarLabel-expand-md"}>
                            <h2>Menu</h2>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/" className='test'>Accueil</Nav.Link>
                                {(() => {
                                    switch (auth?.role) {
                                    case "Administrateur":   return (
                                    <>
                                    <Nav.Item>
                                        <Nav.Link as={Link} to="/accueiladmin">Espace Admin</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link as={Link} to="/admin-export">Export</Nav.Link>
                                    </Nav.Item>
                                    </>
                                    );
                                    case "Client": return (
                                    
                                    <>
                                        <Nav.Item>
                                            <Nav.Link as={Link} to="/accueilclient">Espace Pro</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={Link} to="/documents">Mes Documents</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>    
                                            <Nav.Link as={Link} to="/choixproduit">Commande</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>    
                                            <Nav.Link as={Link} to="/commandes">Mes Paniers</Nav.Link>
                                        </Nav.Item> */}
                                    </>
                                    );
                                    case "Testeur": return (
                                    
                                        <>
                                            <Nav.Item>
                                                <Nav.Link as={Link} to="/accueilclient">Espace Pro</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link as={Link} to="/documents">Mes Documents</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>    
                                                <Nav.Link as={Link} to="/choixproduit">Commande</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>    
                                                <Nav.Link as={Link} to="/commandes">Mes Paniers</Nav.Link>
                                            </Nav.Item>
                                        </>
                                        );

                                    default: return (
                                        null
                                    );
                                }})()}
                                
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                    <Nav className="justify-content-end">
                    {auth?.name ?
                    <div style={{'color':'#fff'}}>Bienvenue {auth.fullName} - {' '} 
                        <button type="button" className="btn btn btn-danger" style={{padding:"6px 6px"}} onClick={async () => {
                                await ServiceAuth.logout()
                                authDispatch({type: 'logout'})
                                navigate("/login")
                            }}> Déconnexion </button>
                    </div>
                    :
                    <div> 
                    <Nav.Link as={Link} to="/login" style={{'color':'#fff'}}>Connectez-vous</Nav.Link>
                    </div>
                    }
                    </Nav>
                </Container>
            </Navbar>    
                
        </div>
    );
}

export default Header;

// <Navbar collapseOnSelect expand="md" className="bg-body-tertiary" bg="dark" data-bs-theme="primary">
//                 <Container fluid id="navbarContainer">
//                 <Navbar.Toggle aria-controls="responsive-navbar-nav"></Navbar.Toggle>
//                 <Navbar.Collapse id="responsive-navbar-nav">
                    
//                     <Nav className="me-auto ">
//                         <Nav.Link as={Link} to="/">Accueil</Nav.Link>
//                         {(() => {
//                             switch (auth?.role) {
//                             case "Administrateur":   return (
//                             <>
//                             <Nav.Item>
//                                 <Nav.Link as={Link} to="/accueiladmin">Espace Admin</Nav.Link>
//                             </Nav.Item>
//                             <Nav.Item>
//                                 <Nav.Link as={Link} to="/choixproduit">Commande</Nav.Link>
//                             </Nav.Item>
//                             </>
//                             );
//                             case "Client": return (
                            
//                             <>
//                                 <Nav.Item>
//                                 <Nav.Link as={Link} to="/accueilclient">Espace Client</Nav.Link>
//                                 </Nav.Item>
//                                 <Nav.Item>
//                                 <Nav.Link as={Link} to="/documents">Mes Documents</Nav.Link>
//                                 </Nav.Item>
//                                 <Nav.Item>    
//                                 <Nav.Link as={Link} to="/choixproduit">Commande</Nav.Link>
//                                 </Nav.Item>
//                             </>
                            
//                             );
//                             default: return (
//                                 null
//                             );
//                         }})()}
                        
//                     </Nav>
                
//                     </Navbar.Collapse>
//                     {/* <div className="navbar-text m-0" style={{color: "white"}}>  */}
//                     <Nav className="justify-content-end">
//                     {auth?.name ?
//                     <div>Bienvenue {auth.businessName} - {' '} 
//                         <button type="button" className="btn btn btn-danger" style={{padding:"6px 6px"}} onClick={async () => {
//                                 await ServiceAuth.logout()
//                                 authDispatch({type: 'logout'})
//                                 navigate("/login")
//                             }}> Déconnexion </button>
//                     </div>
//                     :
//                     <div> 
//                     <Nav.Link as={Link} to="/login">Connectez-vous</Nav.Link>
//                     </div>
//                     }
//                     </Nav>
//                     {/* </div>  */}
//                     </Container>
//             </Navbar>

                // {/* <nav className="navbar navbar-expand-md navbar-dark bg-secondary border-bottom pt-0 pb-0 ps-4 pe-3 mb-0 "> */}
                // <a class="navbar-brand" href="#">Hidden brand</a>
                // <ul className="navbar-nav me-auto">
                //     <li className="nav-item border-end pe-2">                       
                //         <Link className='nav-link active' to='/'>Accueil</Link>
                //     </li>
                //     {(() => {
                //         switch (auth?.role) {
                //         case "Administrateur":   return (
                //         <>
                //             <li className="nav-item border-end pe-2">
                //                 <Link className='nav-link active' to='/accueiladmin'> Espace Admin</Link>
                //             </li>
                //             <li className="nav-item border-end ps-2 pe-2">
                //                 <Link className="nav-link active" to="/choixproduit">Commande</Link>
                //             </li>
                //         </>
                //         );
                //         case "Client": return (
                        
                //         <>
                //             <li className="nav-item border-end ps-2 pe-2">
                //                 <Link className="nav-link active" to="/accueilclient">Espace Client</Link>
                //             </li>
                //             <li className="nav-item border-end ps-2 pe-2">
                //                 <Link className="nav-link active" to="/documents">Mes Documents</Link>
                //             </li>
                //             <li className="nav-item border-end ps-2 pe-2">
                //                 <Link className="nav-link active" to="/choixproduit">Commande</Link>
                //             </li>
                //         </>
                        
                //         );
                //         default: return (
                //             null
                //         );
                //     }})()}
                // </ul>
                // <div className="navbar-text m-0" style={{color: "white"}}> 
                // {auth?.name ?
                // <div>Bienvenue {auth.businessName} - {' '} 
                //     <button type="button" className="btn btn btn-danger" style={{padding:"6px 6px"}} onClick={async () => {
                //             // dispatch({ type: 'logout'})
                //             await ServiceAuth.logout()
                //             // setAuth({})
                //             authDispatch({type: 'logout'})
                //             navigate("/login")
                //         }}> Déconnexion </button>
                // </div>
                // :
                // <div> 
                //     <Link className='nav-link' to="/login">Connectez-vous</Link>
                // </div>
                // }       
                // </div> 