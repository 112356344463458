import axios from "axios";


const API_URL = "/api/admin-queries/";

class ServiceAdmin{

    
    async getSubmissionData(submitId){
        try {
            let options
            const result = await axios.get(API_URL + "submission-data",{params: {
                submitId,
            }, withCredentials: true 
            })
            console.log('result',result.data)
            if(result.status === 200){
                if(result.data !== ""){
                    // options = result.data.map((el) => <option key={Object.values(el)} value={Object.values(el)}>{Object.values(el)}</option>)
                }else{options = null}
                
                return options
            }
        }catch (error) {
            console.log('error',error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
            throw 'Error'
        }   
    }
    async getExecQuery(){
        try {
            let options
            const result = await axios.get(API_URL + "test",{withCredentials: true 
            })
            if(result.status === 200){
                if(result.data !== ""){
                    // options = result.data.map((el) => <option key={Object.values(el)} value={Object.values(el)}>{Object.values(el)}</option>)
                }else{options = null}
                
                return options
            }
        }catch (error) {
            console.log('error',error)
            switch(error.response.status){
                case 401:
                    throw error.response.data
                case 500:
                    throw error.response.data
                default:
                    throw "erreur inconnu"
            }
            throw 'Error'
        }   
    }
}


export default new ServiceAdmin();