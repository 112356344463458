import React, { useState, useEffect } from 'react'
import ServiceQuery from '../services/service_query'
import { useNavigate } from 'react-router-dom';

function Documents () {

    
    const [lignesList, setLignesList] = useState(null)
    const [header, setHeader] = useState(null)
    const [docStatus, setDocStatus] = useState('')
    const [caption, setCaption] = useState('')
    const [documentsArray, setDocumentsArray] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        // React advises to declare the async function directly inside useEffect
        async function callService() {
            setDocStatus('Chargement des documents en cours')
            let returnObject = await ServiceQuery.getAllUserDocuments()
            setDocumentsArray(returnObject)
            if(returnObject[0]?.header){
                setHeader(returnObject[0].header)
                setLignesList(returnObject[0].rows)
                setDocStatus('')
                setCaption('Bon de commandes')
            }else{
                setHeader(null)
                setLignesList(null)
                setCaption('Bon de commandes')
                setDocStatus('Aucun documents de cette catégorie présent sur votre compte')
            }
        }
        callService()
      }, []);
    
    async function handleButtonOnClick(event){
        switch (event.target.id) {
            case 'bon_de_commande':
                if(documentsArray[0]?.header){
                    setHeader(documentsArray[0]?.header)
                    setLignesList(documentsArray[0]?.rows)
                    setDocStatus('')
                    setCaption('Bon de commandes')
                }else{
                    setHeader(null)
                    setLignesList(null)
                    setCaption('Bon de commandes')
                    setDocStatus('Aucun documents de cette catégorie présent sur votre compte')
                }
                break;
            case 'bon_de_livraison':
                if(documentsArray[1]?.header){  
                    setHeader(documentsArray[1].header)
                    setLignesList(documentsArray[1].rows)
                    setDocStatus('')
                    setCaption('Bon de livraisons')
                }   else{
                    setHeader(null)
                    setLignesList(null)
                    setCaption('Bon de livraisons')
                    setDocStatus('Aucun documents de cette catégorie présent sur votre compte')
                }
                break;
            case 'facture':
                if(documentsArray[2]?.header){    
                    setHeader(documentsArray[2].header)
                    setLignesList(documentsArray[2].rows)
                    setDocStatus('')
                    setCaption('Factures')
                }else{
                    setHeader(null)
                    setLignesList(null)
                    setCaption('Factures')
                    setDocStatus('Aucun documents de cette catégorie présent sur votre compte')
                }
                break;
            default:
                break;
        }

    }

    async function handleRefreshOnClick(event){
        setDocStatus('Chargement des documents en cours')
        let returnObject = await ServiceQuery.getAllUserDocuments()
        setDocumentsArray(returnObject)
        if(returnObject[0]?.header){
            setHeader(returnObject[0].header)
            setLignesList(returnObject[0].rows)
            setDocStatus('')
            setCaption('Bon de commandes')
        }else{
            setHeader(null)
            setLignesList(null)
            setCaption('Bon de commandes')
            setDocStatus('Aucun documents de cette catégorie présent sur votre compte')
        }
    }

    return (
        <div>
            <div className='container text-center' style={{padding:"15px"}}>
                <div className='row' >
                    {/* <div className='col'>
                        <button id="opportunity" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Opportunités</button>
                    </div> */}
                    <div className='col'>
                        <button id="bon_de_commande" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Bon de commandes</button>
                    </div>
                    <div className='col'>
                        <button id="bon_de_livraison" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Bon de livraisons</button>
                    </div>
                    <div className='col'>
                        <button id="facture" className="btn btn-primary" type="button" onClick={handleButtonOnClick}>Factures</button>
                    </div>
                    <div className='col'>
                        <button style={{margin: '0 10px 0 10px'}} id="refresh_order" className='btn btn-primary' type='button' onClick={handleRefreshOnClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
                <div>
                    <h2>{caption}</h2>
                    {docStatus && <div className="alert alert-warning">{docStatus}</div>}
                    <div className='table-div'>
                        <table className='table table-striped caption-top'>
                            <thead>
                                {header}
                            </thead>
                            <tbody>
                                {lignesList}
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>

    )
}

export default Documents